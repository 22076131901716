@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;500;700&family=Urbanist:wght@700&display=swap');

:root {
      --black: rgba(0, 0, 0, 1);
      --white: rgba(255, 255, 255, 1);

      --yellow: rgba(255, 206, 0, 1);
      --light-yellow: rgba(255, 206, 0, 0.3);
}

body {
  margin: 0;
  font-size:18px;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

h1, h2, h3 {
      font-family: 'Urbanist', sans-serif;
}

h1 {
      font-size:3rem;
}

h2 {
      font-size:2rem;
}

p {
      font-weight:300;
}

#header {
      padding: 35px 0;
      background-color:var(--black);
      color:var(--white);
}

#content {
      padding: 75px 0;
}

#footer {

      border-top: 10px solid var(--yellow);
      padding: 50px 0;
      background-color:var(--black);
      color:var(--white);
}

#footer p {
      font-size:70%;
      margin-bottom:0;
      opacity:0.7;
}

#footer img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      display:block;
      opacity:0.4;
    }

.logo-header {
      width:200px;
}

.logo-footer {
      width:200px;
}

.backTop {
      font-size: 3rem;
      opacity: 0.3;
}

.backTop:hover {
      cursor:pointer;
      color:var(--yellow);
      opacity: 1;
}

.header-menu-toggle {
      cursor:pointer;
      font-size:2rem;
}

.header-menu-toggle:hover {
      color:var(--yellow);
}

.ant-dropdown-menu {
      border-radius:3px;
      padding: 0 !important;
}

.ant-dropdown-menu-item {
      font-weight:400;
      padding: 15px 40px !important;
}

.ant-dropdown-menu-item:hover {
      background-color:var(--light-yellow);
}

.clickable {cursor:pointer;}

.ant-btn-primary {
      border:0;
      outline:0;
      color:var(--white);
      background-color:var(--black);
      border-bottom:6px solid var(--yellow);
}

.ant-btn-primary:hover {
      border:0;
      outline:0;
      color:var(--white);
      background-color:var(--black);
      border-bottom:6px solid var(--yellow);
}

.ant-btn-lg {
      padding: 12px 25px;
      font-size:24px;
      height:auto;
      font-weight:600;
}

#map-wrapper {
      position:relative;
}

#over-map-lookup {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width:100%;
      height:30vh;
      background-color: none;
}

@media only screen and (max-width: 768px) {
      #over-map-lookup {
            margin-top:10vh;
      }
    }

#over-map-lookup .ant-input {
      font-family: 'Urbanist', sans-serif;
      font-weight:700;
      letter-spacing:-1px;
      color:var(--black);
      background-color: var(--yellow);
      font-size:2rem;
      border:0 !important;
      outline:none !important;
      text-transform: uppercase;
}

#over-map-lookup .ant-input:focus { box-shadow: none; }

#over-map-lookup .ant-input-prefix {
      margin-right: 25px;
}

#over-map-lookup .ant-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */

      text-transform: none;
      color: rgba(255, 255, 255, 0.7);
      opacity: 1; /* Firefox */
    }
    
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      text-transform: none;
      color: rgba(255, 255, 255, 0.7);
    }
    
    ::-ms-input-placeholder { /* Microsoft Edge */
      text-transform: none;
      color: rgba(255, 255, 255, 0.7);
    }

    .idpc_autocomplete {
      /* font-size: 4rem; */
      border:0 !important;
      outline:none !important;
      
    }

    .idpc_ul {
      font-size:0.8rem;
      font-weight:300;
      margin-top:25px;
    }

    .idpc_toolbar {
      display:none;
    }

    #quote-card {
      background-color:#FFF;
      padding:25px 50px;
      border-top:3px solid var(--yellow);
      height:62vh;
    }

    .idpc_af {
      /* background-color:rgba(255, 206, 0, 0.4) !important; */
      margin-top: 15px !important;
      border:0 !important;
    }

    #work-with {
      padding: 50px 0;
    }

    #work-with img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      display:block;
      margin-left: auto;
      margin-right: auto;
      height:100px;
      opacity:0.4;
    }
    
    @media only screen and (max-width: 768px) {
      #footer {
            padding-top:0;
            text-align:center;
      }
      #work-with img {
        margin-bottom: 50px;
      }
      #work-with {
            margin-bottom:-50px;
      }
    }



    #over-map-quotebox {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin-top:50px; 
      width:100%;
      height:90%;
      background-color: none;
}

@media only screen and (max-width: 768px) {
      #over-map-quotebox {
            margin-top:25px;
      }
      .toggle {
            padding: 5px 0;
      }
    }

.toggle {
      cursor:pointer;
      text-align:center;
      background-color:rgba(0,0,0,0.04);
      padding: 25px 0;
}

.quote-summary {
      padding:50px;
}



.toggle-slim {
      font-size:14px;
      padding: 5px 5px;
}

.toggle-active {
      background-color: var(--light-yellow)
}

#quote-card .ant-row {
      margin-bottom:16px;
}





.quote-summary p, .quote-summary ul {
      font-size: 80%;
}

.quote-summary ul {
      margin-top: 0;
      margin-left: -18px;
      font-weight: 300;
      margin-bottom: 0;
}

.ant-btn-link {

  font-size:18px;
  font-family: 'Inter', sans-serif;
  font-weight:300;
  font-style:italic;

}

.loader {
      margin-top:15px;
      width: 48px;
      height: 48px;
      border: 2px solid #2A2A2A;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
} 
    
.loader::after {
content: '';  
box-sizing: border-box;
position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
width: 56px;
height: 56px;
border-radius: 50%;
border: 3px solid transparent;
border-bottom-color: var(--yellow);
}

@keyframes rotation {
0% {
      transform: rotate(0deg);
}
100% {
      transform: rotate(360deg);
}
} 

.summary-card {
padding:50px;
border: 2px dotted var(--yellow);
border-radius: 10px;
margin-bottom: 50px;
}

.orderh2icon {
vertical-align:-5px;
margin-right: 10px;
}

#static-content-wrapper {padding: 75px 0 75px 0;}
#static-content-wrapper h2 {margin-top:50px;}

#footer a {color: var(--white); font-weight:300;}
#footer a:hover {color:var(--yellow); cursor: pointer;}

.footer-link {display:inline-block; margin-right:50px; font-weight:300; font-size:80%;}
.footer-link:hover {color:var(--yellow); cursor: pointer;}

.help-icon {
      font-size:16px;
      opacity:0.6;
      vertical-align:-3px;
      margin-left:2px;
      cursor:pointer;
}

.ant-modal-content li {
      font-weight:300;
}

.ant-modal-content .ant-alert {
      margin-bottom:5px;
}

#billing-address-wrapper {
      margin: 50px 0 0 0;
}

.ant-drawer p {
      background-color:rgba(0,0,0,0.05);
      padding:15px;
      margin-bottom:15px;
}

.ant-drawer p:hover {
      background-color:var(--light-yellow);
}

.service-card {
      margin-bottom:25px;
}

.service-card img {
      width:100%;
      height:500px;
}

.service-title {
      width:100%;
      margin-top:-50px;
      background-color:rgba(0,0,0,0.85);
      color:var(--white); 
      padding:35px 50px 50px 50px;

}

.service-title h2 {
      color:var(--white);
      margin-bottom:0; 
}

#contactForm input {
      line-height:34px;
}

@media only screen and (max-width: 768px) {
      #over-map-quotebox {
            margin-top:25px;
      }
      .toggle {
            padding: 5px 0;
      }
      #quote-card{
            padding:25px;
            height:80vh;
      }
      .quote-summary{
            text-align:center;
            padding:25px;
      }
      .quote-summary ul{
            margin:0 !important;
            padding:0 !important;
            list-style: none;
      }
      .summary-card{
            padding:25px;
      }
    }

    .telLink {
      color:inherit;
    }

    .ant-alert-with-description .ant-alert-description {
      display: block;
      margin-bottom: 4px;
      color: rgba(0,0,0,.85);
      font-size: 16px;
  }